import { Box, Typography } from '@mui/material';
import { MarkdownRenderer } from 'components/MarkdownRenderer';
import { PropsWithChildren } from 'react';

export default function AirdropTermsWrapper({
  children,
  markdown,
}: { markdown: string } & PropsWithChildren) {
  return (
    <Box
      sx={{
        mt: 3,
        position: 'relative',
        overflow: 'scroll',
        background: 'linear-gradient(127deg, #EAF4DC 0%, #D5E9BB 100%)',
        maxHeight: '70vh',
        overscrollBehavior: 'none',
      }}
    >
      <MarkdownRenderer
        components={{
          h1: ({ children }) => (
            <Typography
              sx={{
                top: 20,
                textDecoration: 'underline',
                p: 2,
                pt: 6,
              }}
              variant="h1"
            >
              {children}
            </Typography>
          ),
        }}
        markdown={markdown}
      />
      {children}
    </Box>
  );
}
