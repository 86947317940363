import { useQuery } from '@tanstack/react-query';
import { readContracts } from '@wagmi/core';
import ERC20_VAULT_ABI from 'core/contracts/ERC20_VAULT_ABI';
import REALT_VAULT_ABI from 'core/contracts/REALT_VAULT_ABI';
import STAKE_ABI from 'core/contracts/STAKE_ABI';
import { commify, formatWithPrecision, supportedEthChainId } from 'core/utils';
import { useContractRead } from 'hooks/useContractRead';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Address, erc20Abi } from 'viem';
import { useAccount } from 'wagmi';

import { ALERT_SEVERITY, useAlerts } from './AlertsContext';
import { useStakingInfo } from './StakingInfoContext';
import { useWagmi } from './WagmiContext';

interface IStakedTokensContext {
  tokens: Record<Address, TokenInfo>;
  prices: Record<Address, number>;
  reAltPerStAltRate: number;
  refetchAllVaultTokens: (vaultId: string) => Promise<void>;
  fetchTokenBalance: (
    tokenAddress: Address,
    operatorAddress?: Address,
    tokenType?: 'vault' | 'erc20'
  ) => Promise<void>;
  convertReAltDialogOpen: boolean;
  setConvertReAltDialogOpen: Dispatch<SetStateAction<boolean>>;
}
const StakedTokensContext = createContext<IStakedTokensContext>({} as IStakedTokensContext);

export const useStakedTokens = () => useContext(StakedTokensContext);

interface TokenInfo {
  balance: bigint;
  decimals: number;
  symbol: string;
  name: string;
  formattedBalance: string;
  refetch: () => Promise<void>;
}

const reAltAddr = window.appConfig?.erc4626Vault;
const altAddr = Object.entries(window.appConfig?.erc20Info || {})?.find(
  ([, tokenInfo]) => tokenInfo?.coingeckoId === 'altlayer'
)?.[0] as Address;
const v2Vaults = window.appConfig?.v2Vaults;
const erc20Vaults = Object.values(v2Vaults || {})?.filter(cur => Boolean(cur?.erc20VaultAddr));
const stAltConfig = window.appConfig?.vaults?.mach_alpha;
const stAltAddr = stAltConfig?.stakingContracts?.[0];

export const StakedTokensContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { address } = useAccount();
  const { config } = useWagmi();
  const { addAlert } = useAlerts();
  const { isAllVaultInfoFetched, stakingInfo } = useStakingInfo();
  const [tokens, setTokens] = useState({} as Record<Address, TokenInfo>);
  const [prices, setPrices] = useState({} as Record<Address, number>);
  const [convertReAltDialogOpen, setConvertReAltDialogOpen] = useState(false);

  const { showReadContractError } = useAlerts();

  const { data: previewShares, refetch: fetchExchangeRate } = useContractRead<bigint>({
    address: reAltAddr,
    abi: REALT_VAULT_ABI,
    functionName: 'previewDeposit',
    args: [1_000000_000000_000000n],
    chainId: supportedEthChainId,
    enabled: false,
    onError: (err: Error) => {
      showReadContractError('previewDeposit', err);
    },
  });

  useEffect(() => {
    fetchExchangeRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const reAltPerStAltRate = Number(previewShares) / 1_000000_000000_000000 || 0;

  useEffect(() => {
    if (prices?.[altAddr] && reAltPerStAltRate && !prices?.[reAltAddr]) {
      // calculate theoretical price of reALT
      setPrices(prev => ({ ...prev, [reAltAddr]: prev?.[altAddr] / reAltPerStAltRate }));
    }
  }, [prices, reAltPerStAltRate]);

  const allStakedTokenAddresses = useMemo(() => {
    const uniqueAddrs = new Set<Address>();

    Object.values(stakingInfo || {})?.forEach(vaultStakingInfo => {
      Object.values(vaultStakingInfo || {})?.forEach(cur => {
        if (cur.stakedTokenAddr) {
          uniqueAddrs.add(cur.stakedTokenAddr);
        }
      });
    });
    Object.values(v2Vaults || {})?.forEach(vault => {
      if (vault.stakedTokenAddr) {
        uniqueAddrs.add(vault.stakedTokenAddr);
      }
    });
    uniqueAddrs.add(stAltAddr);
    uniqueAddrs.add(reAltAddr);

    return Array.from(uniqueAddrs);
  }, [stakingInfo]);

  const fetchAllTokenSymbols = useCallback(async () => {
    const symbols = await readContracts(config, {
      contracts: allStakedTokenAddresses?.map(cur => ({
        address: cur,
        abi: erc20Abi,
        functionName: 'symbol',
        chainId: supportedEthChainId,
      })),
    });

    symbols?.forEach((cur, index) =>
      setTokens(prev => {
        const stakedTokenAddr = allStakedTokenAddresses?.[index];

        return {
          ...prev,
          [stakedTokenAddr]: {
            ...prev[stakedTokenAddr],
            symbol: cur?.result === 'STALT' ? 'stALT' : cur?.result,
          },
        };
      })
    );
  }, [allStakedTokenAddresses, config]);

  const fetchTokenBalance = useCallback(
    async (tokenAddress: Address, operatorAddress?: Address, tokenType?: 'vault' | 'erc20') => {
      if (!address) return;

      const [balance, decimals, symbol, name] = await readContracts(config, {
        contracts: [
          {
            address: tokenAddress,
            abi: operatorAddress ? STAKE_ABI : ERC20_VAULT_ABI,
            functionName: operatorAddress
              ? 'votingStake'
              : tokenType === 'vault'
              ? 'balanceOf' // TODO: Change this to 'activeAssets' if cooldown is implemented on erc20 vaults
              : 'balanceOf',
            args: operatorAddress ? [address || '0x', operatorAddress] : [address || '0x'],
            chainId: supportedEthChainId,
          },
          {
            address: tokenAddress,
            abi: erc20Abi,
            functionName: 'decimals',
            chainId: supportedEthChainId,
          },
          {
            address: tokenAddress,
            abi: erc20Abi,
            functionName: 'symbol',
            chainId: supportedEthChainId,
          },
          {
            address: tokenAddress,
            abi: erc20Abi,
            functionName: 'name',
            chainId: supportedEthChainId,
          },
        ],
      });

      setTokens(prev => ({
        ...prev,
        [tokenAddress]: {
          balance: balance?.result,
          symbol: symbol?.result === 'STALT' ? 'stALT' : symbol?.result,
          name: name?.result,
          decimals: decimals?.result,
          formattedBalance: commify(
            formatWithPrecision(balance?.result || 0n, undefined, decimals?.result)
          ),
          refetch: () => fetchTokenBalance(tokenAddress),
        },
      }));
    },
    [config, address]
  );

  const refetchAllVaultTokens = useCallback(
    async (vaultId: string) => {
      const contractAddresses = Object.values(stakingInfo[vaultId] || {})?.map(
        cur => cur.stakedTokenAddr
      );

      await Promise.all(contractAddresses?.map(cur => cur && fetchTokenBalance(cur)));
    },
    [fetchTokenBalance, stakingInfo]
  );

  const {
    error: pricesError,
    isLoading: isPricesLoading,
    isSuccess: isPricesSuccess,
    refetch: refetchPrices,
  } = useQuery({
    queryKey: ['FETCH_TOKEN_PRICES'],
    enabled: false,
    queryFn: async () => {
      try {
        const allRewardAndStakedTokens = Object.keys(window.appConfig?.erc20Info);
        const coingeckoIds = allRewardAndStakedTokens?.map(addr => {
          const id = window.appConfig?.erc20Info?.[addr as Address]?.coingeckoId;

          if (!id) {
            console.error('No coingecko ID found for address: ', addr);
          }

          return id;
        });

        const priceApiRes = await fetch(
          `https://coins.llama.fi/prices/current/${coingeckoIds
            ?.map(id => `coingecko:${id}`)
            ?.join(',')}`
        );
        const res = await priceApiRes?.json();

        if (res?.status?.error_code === 429) {
          addAlert({
            severity: ALERT_SEVERITY.ERROR,
            title: 'Could not fetch token prices',
            desc: 'Rate limit exceeded. Retrying in 30s...',
            timeout: 30000,
          });
          setTimeout(refetchPrices, 30000); // refetch prices in 30s if rate limited

          return null;
        }

        const priceMap = allRewardAndStakedTokens?.reduce(
          (acc, addr, index) => ({
            ...acc,
            [addr]: res?.coins?.[`coingecko:${coingeckoIds?.[index]}`]?.price,
          }),
          {} as Record<Address, number>
        );

        setPrices(priceMap);
        setTimeout(refetchPrices, 60000); // successful, refetch prices every 60s

        return priceMap;
      } catch (err) {
        console.error('Failed to fetch prices');
      }
    },
  });

  const fetchAllVaultTokenBalances = useCallback(() => {
    const stAltOperatorAddr = stakingInfo?.mach_alpha?.[stAltAddr]?.operatorAddresses?.[0];

    if (stAltOperatorAddr) {
      fetchTokenBalance(stAltAddr, stAltOperatorAddr);
      fetchTokenBalance(reAltAddr);
    }

    // fetch erc20 vault token balances
    erc20Vaults?.forEach(
      vault => fetchTokenBalance(vault.erc20VaultAddr as Address, undefined, 'vault')
      // fetchTokenBalance(vault.erc20VaultAddr as Address)
    );
    // fetch v2 vault staked token balances
    Object.values(v2Vaults || {})?.forEach(vault => {
      if (vault.stakedTokenAddr) {
        fetchTokenBalance(vault.stakedTokenAddr);
      }
    });
  }, [fetchTokenBalance, stakingInfo?.mach_alpha]);

  useEffect(() => {
    fetchAllVaultTokenBalances();
  }, [fetchAllVaultTokenBalances]);

  useEffect(() => {
    if (isAllVaultInfoFetched && !isPricesLoading && !isPricesSuccess && !pricesError) {
      refetchPrices();
      fetchAllTokenSymbols();
    }
  }, [
    isAllVaultInfoFetched,
    fetchAllTokenSymbols,
    isPricesLoading,
    isPricesSuccess,
    pricesError,
    refetchPrices,
  ]);

  console.debug('tokens: ', tokens);

  return (
    <StakedTokensContext.Provider
      value={{
        tokens,
        prices,
        refetchAllVaultTokens,
        fetchTokenBalance,
        reAltPerStAltRate,
        convertReAltDialogOpen,
        setConvertReAltDialogOpen,
      }}
    >
      {children}
    </StakedTokensContext.Provider>
  );
};
