import numeral from 'numeral';
import { formatUnits, parseUnits } from 'viem';
import { base, baseSepolia, mainnet, sepolia } from 'viem/chains';

function trimZero(v: string) {
  const regexp = /(?:\.0*|(\.\d+?)0+)$/;

  return v.replace(regexp, '$1');
}

export function parseUnitsSafe(value: string, decimals: number): bigint {
  let result;

  try {
    result = parseUnits(value, decimals);
  } catch (error) {
    console.error('Parse units error: ', error);
    result = 0n;
  }

  return result;
}

export function isPositiveNumber(target: string): boolean {
  return /^\d+(\.{0,1}\d+){0,1}$/.test(target);
}

/**
 * @description Formats a number / bigint to 6 significant figures (default) and trims trailing zeros.
 * @description 0 dp for large integers
 * @returns string
 */
export function formatWithPrecision(value: number | bigint, _precision = 4, decimals = 18) {
  const stringVal = typeof value === 'number' ? value?.toString() : formatUnits(value, decimals);
  const integerLength = stringVal?.split('.')?.[0]?.length;
  // 0.123124125123 -> 0.1231
  // 12345123754.12412412124 -> 12345123754
  // 123.12344 -> 123.1
  // 123456.789 -> 123457
  const precision = integerLength > _precision ? integerLength : _precision;

  return trimZero(Number(stringVal)?.toPrecision(precision));
}

export function commify(amount: number | string) {
  // With usage of formatWithPrecision, there is a possibility of amount string being >= "1e21" or <= "1e-7" which will result in NaN
  const result = numeral(amount)?.format('0,0.[000000000000000000]');

  return result === 'NaN' ? '0' : result;
}

export const msToDaysHrsMinsSeconds = (ms: number) => {
  const diffInSeconds = Math.ceil(ms / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  const seconds = diffInSeconds % 60;
  const minutes = diffInMinutes % 60;
  const hours = diffInHours % 24;

  if (diffInDays < 0 || hours < 0 || minutes < 0 || seconds < 0) {
    return [0, 0, 0, 0];
  }

  return [diffInDays, hours, minutes, seconds];
};

export const getTimeLeft = (countdownEndDate: Date): string => {
  if (isNaN(countdownEndDate.getTime())) {
    return 'Undefined';
  }

  const diffInMsUntilEnd = countdownEndDate?.getTime() - Date.now();
  const dateTimeArr = msToDaysHrsMinsSeconds(diffInMsUntilEnd);
  const units = ['d', 'h', 'm', 's'];

  return (
    dateTimeArr.reduce(
      (result, cur, index) => (cur > 0 ? result + ' ' + String(cur) + units[index] : result),
      ''
    ) || '0s'
  );
};

const vaultConfigs = window.appConfig?.vaults || {};

export const getStakingContracts = (vaultId: string) => vaultConfigs?.[vaultId]?.stakingContracts;

export const SECONDS_IN_YEAR = 60n * 60n * 24n * 365n;

export const WEEKS_IN_YEAR = 52.1429;

// Either mainnet or sepolia
export const supportedEthChainId = window.appConfig?.appNetworks?.find(cur =>
  ([mainnet.id, sepolia.id] as number[])?.includes(cur?.id)
)?.id;

// Either base mainnet or base sepolia
export const supportedBaseChainId = window.appConfig?.appNetworks?.find(cur =>
  ([base.id, baseSepolia.id] as number[])?.includes(cur?.id)
)?.id;
