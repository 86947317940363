import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonProps, FormHelperText } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { FC, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useChainId, useSwitchChain } from 'wagmi';

import { ConnectWithTermsButton } from './CustomConnectButton';

const ConnectAndSwitchNetworkButton: FC<
  { targetId: number; isConnected: boolean } & ButtonProps
> = ({ isConnected, targetId }) => {
  const chainId = useChainId();
  const { switchChain } = useSwitchChain();

  useEffect(() => {
    return () => {
      // immediately prompt user to switch network once connected
      switchChain({ chainId: targetId });
    };
  }, [isConnected, chainId, targetId, switchChain]);

  return <ConnectWithTermsButton />;
};

const EnsureNetworkButton: FC<
  {
    children?: React.ReactNode;
    targetId: number;
    buttonEndAdornment?: React.ReactNode;
  } & ButtonProps
> = ({ buttonEndAdornment, children, targetId, ...props }) => {
  const { switchChain } = useSwitchChain();
  const targetName = window.appConfig?.appNetworks?.find(cur => cur?.id === targetId)?.name;

  return (
    <ConnectButton.Custom>
      {({ account, authenticationStatus, chain, mounted }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');

        if (!ready) {
          return <LoadingButton fullWidth loading={true} variant="contained" {...props} />;
        }

        if (!connected && !window.ethereum && (isMobile || isTablet)) {
          return (
            <Box>
              <FormHelperText sx={{ mb: 2, fontWeight: 700, fontSize: 12 }}>
                Incompatible browser detected.
              </FormHelperText>
              <Button
                href={`https://metamask.app.link/dapp/${window.location.origin}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Open in Metamask app
              </Button>
            </Box>
          );
        }

        if (!connected) {
          return (
            <ConnectAndSwitchNetworkButton
              isConnected={Boolean(connected)}
              targetId={targetId}
              {...props}
            />
          );
        }

        if (chain?.id !== targetId) {
          return (
            <Button
              fullWidth
              onClick={() => {
                switchChain ? switchChain({ chainId: targetId }) : window.location.reload();
              }}
              variant="contained"
              {...props}
            >
              Switch to {targetName} {buttonEndAdornment}
            </Button>
          );
        }

        return children;
      }}
    </ConnectButton.Custom>
  );
};

export default EnsureNetworkButton;
