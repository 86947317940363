import { readContract } from '@wagmi/core';
import REALT_VAULT_ABI from 'core/contracts/REALT_VAULT_ABI';
import { supportedEthChainId } from 'core/utils';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { V2VaultConfig } from 'types';
import { Address } from 'viem';
import { useAccount, useConfig } from 'wagmi';

import { useStakedTokens } from './StakedTokensContext';

const vaultAddr = window.appConfig?.erc4626Vault;

interface IV2VaultsContext {
  vaultMap: Record<number, V2VaultData>;
  optInVaultMap: Record<number, boolean>;
  userV2Vaults: number[];
  fetchOptInStatuses: () => Promise<void>;
}
const V2VaultsContext = createContext<IV2VaultsContext>({} as IV2VaultsContext);

export const useV2Vaults = () => useContext(V2VaultsContext);

export interface V2VaultData extends V2VaultConfig {
  id: number;
  startTime: bigint;
  endTime: bigint;
}

const v2VaultsConfigs = window.appConfig?.v2Vaults || {};

export const V2VaultsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const config = useConfig();
  const [vaultMap, setVaultMap] = useState<Record<number, V2VaultData>>({});
  const [optInVaultMap, setOptInVaultMap] = useState<Record<number, boolean>>({});
  const { tokens } = useStakedTokens();
  const { address } = useAccount();

  const optedInVaults = useMemo(
    () =>
      Object.entries(optInVaultMap || {})?.reduce(
        (acc, [vaultId, optedIn]) => (optedIn ? [...acc, Number(vaultId)] : acc),
        [] as number[]
      ),
    [optInVaultMap]
  );

  const erc20VaultsWithBalance = useMemo(
    () =>
      Object.entries(v2VaultsConfigs)
        .filter(([, vault]) => {
          return Boolean(tokens?.[vault.erc20VaultAddr as Address]?.balance);
        })
        ?.map(entry => Number(entry?.[0])),
    [tokens]
  );

  const userV2Vaults = useMemo(
    () => Array.from(new Set([...optedInVaults, ...erc20VaultsWithBalance])),
    [optedInVaults, erc20VaultsWithBalance]
  );

  const fetchAllVaults = useCallback(async () => {
    const totalRewardPools = await readContract(config, {
      abi: REALT_VAULT_ABI,
      address: vaultAddr,
      functionName: 'totalRewardPools',
      chainId: supportedEthChainId,
    });

    const rewardPoolIds = new Array(Number(totalRewardPools))
      ?.fill(0n)
      ?.map((_, index) => BigInt(index + 1));

    const rewardPoolsData = await Promise.all(
      rewardPoolIds?.map(id =>
        readContract(config, {
          abi: REALT_VAULT_ABI,
          address: vaultAddr,
          functionName: 'RewardPools',
          chainId: supportedEthChainId,
          args: [id],
        })
      )
    );

    const _vaultMap = rewardPoolsData?.reduce((acc, result, index) => {
      const vaultId = index + 1;

      const [startTime, endTime] = result || [];

      if (Date.now() > Number(endTime) * 1000 || Date.now() < Number(startTime) * 1000) {
        return acc;
      }

      return {
        ...acc,
        [vaultId]: {
          id: vaultId,
          startTime,
          endTime,
          ...v2VaultsConfigs?.[vaultId],
        },
      };
    }, {} as Record<number, V2VaultData>);

    console.debug('_vaultMap: ', _vaultMap);
    setVaultMap(_vaultMap);
  }, [config]);

  const fetchOptInStatuses = useCallback(async () => {
    if (!address) return;

    const vaultIds = Object.keys(vaultMap || {})?.map(key => Number(key));

    const optedInVaults = (
      await Promise.all(
        vaultIds?.map(id =>
          readContract(config, {
            abi: REALT_VAULT_ABI,
            address: vaultAddr,
            functionName: 'optedInUsers',
            args: [BigInt(id), address],
            chainId: supportedEthChainId,
          })
        )
      )
    )?.reduce(
      (acc, result, index) => ({
        ...acc,
        [vaultIds[index]]: result,
      }),
      {} as Record<number, boolean>
    );

    setOptInVaultMap(optedInVaults);
  }, [config, vaultMap, address]);

  useEffect(() => {
    fetchOptInStatuses();
  }, [fetchOptInStatuses]);

  useEffect(() => {
    fetchAllVaults();
  }, [fetchAllVaults]);

  return (
    <V2VaultsContext.Provider value={{ vaultMap, optInVaultMap, fetchOptInStatuses, userV2Vaults }}>
      {children}
    </V2VaultsContext.Provider>
  );
};
