import { Button, ButtonProps, TableCell } from '@mui/material';
import { supportedEthChainId } from 'core/utils';
import useAirdrops from 'hooks/useAirdrops';
import { useNavigate } from 'react-router-dom';

import { TokenClaimData } from '../../../api/_types/kv';
import CountdownTimer, { CurrentPeriod } from './CountdownTimer';

export default function ClaimCells({
  sx,
  tokenClaimData,
  ...props
}: { tokenClaimData: TokenClaimData } & ButtonProps) {
  const navigate = useNavigate();

  const { hasClaimed } = useAirdrops({ airdropKey: tokenClaimData?.id });

  return (
    <>
      <CountdownTimer
        component={TableCell}
        renderChildren={(currentPeriod: CurrentPeriod) => (
          <TableCell align="right">
            <Button
              disabled={currentPeriod !== 'Active' || hasClaimed || !tokenClaimData?.amount}
              onClick={() => {
                navigate(
                  '/rewards/' +
                    tokenClaimData?.id +
                    '/' +
                    String(tokenClaimData?.config?.chainId || supportedEthChainId)
                );
              }}
              sx={{
                ...sx,
              }}
              variant="contained"
              {...props}
            >
              Claim
            </Button>
          </TableCell>
        )}
        tokenClaimData={tokenClaimData}
      />
    </>
  );
}
