import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  ContainerProps,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import IconGeoblock from 'components/icons/IconGeoblock';
import { Web3Route } from 'components/Web3Route';
import { formatWithPrecision, supportedEthChainId } from 'core/utils';
import useAirdrops from 'hooks/useAirdrops';
import numeral from 'numeral';
import CountdownTimer, { CurrentPeriod } from 'pages/Rewards/CountdownTimer';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';

import AirdropTerms from './AirdropTerms/AirdropTermsWrapper';
import { termsMap } from './AirdropTerms';

const CardContainer: FC<ContainerProps> = ({ children, ...props }) => (
  <Container
    maxWidth="sm"
    sx={{
      px: { xs: 3, lg: 4 },
      py: 3,
      background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
      borderBottom: '1px solid #20272317',
    }}
    {...props}
  >
    {children}
  </Container>
);

export const DedicatedClaimPage = () => {
  const { airdropKey, chainId: _chainId } = useParams();
  const chainId = _chainId ? Number(_chainId) : supportedEthChainId;
  const { address } = useAccount();
  const termsMarkdown = termsMap?.[airdropKey || ''];

  const {
    claim,
    claimWSignature,
    claimWSignatureError,
    hasClaimed,
    isClaiming,
    isLoading,
    isSigning,
    resetSignature,
    signMessage,
    signature,
    tokenClaimData,
  } = useAirdrops({ airdropKey });

  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [trezorSafeChecked, setTrezorSafeChecked] = useState(false);

  useEffect(() => {
    if (!trezorSafeChecked) {
      resetSignature();
    }
  }, [trezorSafeChecked, address, resetSignature]);

  useEffect(() => {
    if (signature && !claimWSignatureError && !isClaiming) {
      setTimeout(claimWSignature, 1000);
    }
  }, [signature, claimWSignatureError, claimWSignature, isClaiming]);

  return (
    <Stack
      sx={{
        minHeight: { md: 'calc(100vh - 250px)', xs: '40vh' },
        alignItems: 'center',
        justifyContent: 'center',
        background: 'url(/imgs/eigenBg.png) repeat center',
        backgroundSize: 'contain',
      }}
    >
      <Web3Route chainId={chainId}>
        {isLoading || !tokenClaimData?.amount ? (
          <>
            <Box
              component="img"
              src={tokenClaimData?.config?.iconUrl}
              sx={{
                width: 150,
                bgcolor: 'white',
                border: '1px solid rgb(237,235,254)',
                mb: 4,
              }}
            />
            <Typography alignItems="center" variant="h2">
              {isLoading ? (
                <CircularProgress />
              ) : (
                `You are not eligible for this ${tokenClaimData.config?.tokenName} drop.`
              )}
            </Typography>
          </>
        ) : (
          <>
            {tokenClaimData?.geoblocked ? (
              <CardContainer>
                <Stack>
                  <IconGeoblock sx={{ width: 80, height: 80, mx: 'auto' }} />
                  <Typography
                    mt={3}
                    sx={{ fontSize: 18, textAlign: 'center', maxWidth: '252px', mx: 'auto' }}
                  >
                    {tokenClaimData?.config?.tokenName} claim feature is not available in your
                    region
                  </Typography>
                  <Typography
                    mt={3}
                    sx={{
                      fontSize: 11,
                      fontWeight: 500,
                      letterSpacing: '0.66px',
                      lineHeight: '16px',
                      px: '12px',
                      py: '10px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Due to legal restrictions, we cannot accept users from your country.
                  </Typography>
                </Stack>
              </CardContainer>
            ) : !agreedToTerms ? (
              <CardContainer maxWidth="md" sx={{ px: 0 }}>
                <AirdropTerms markdown={termsMarkdown} />
                <Button
                  fullWidth
                  onClick={() => {
                    setAgreedToTerms(true);
                  }}
                  sx={{ mx: 'auto', mt: 3, mb: 6 }}
                  variant="contained"
                >
                  Agree
                </Button>
              </CardContainer>
            ) : hasClaimed ? (
              <Typography variant="h2">
                You have successfully claimed{' '}
                {formatWithPrecision(BigInt(tokenClaimData?.amount || 0))}{' '}
                {tokenClaimData?.config?.tokenName}
              </Typography>
            ) : (
              <CardContainer>
                <Stack>
                  <Typography textAlign="center" variant="h1">
                    You are eligible!
                  </Typography>
                  <CountdownTimer
                    renderChildren={(currentPeriod: CurrentPeriod) =>
                      currentPeriod !== 'Ended' && (
                        <Stack>
                          <Typography
                            sx={{
                              mt: 5,
                              fontSize: 13,
                              fontWeight: 500,
                              lineHeight: '18px',
                              letterSpacing: '0.78px',
                              textTransform: 'uppercase',
                              textAlign: 'center',
                            }}
                          >
                            You will receive
                          </Typography>

                          <Typography textAlign="center" variant="h1">
                            {numeral(formatWithPrecision(BigInt(tokenClaimData?.amount)))?.format(
                              '0,0.000'
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: 14, sm: 18 },
                              color: theme => theme.colors.functional.text.primary,
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              columnGap: 0.75,
                              mb: 5,
                            }}
                          >
                            <Box
                              component="img"
                              src={tokenClaimData?.config?.iconUrl}
                              sx={{
                                width: 26,
                                bgcolor: 'white',
                                border: '1px solid rgb(237,235,254)',
                              }}
                            />
                            {tokenClaimData?.config?.tokenName}
                          </Typography>
                          <Stack alignItems="center" direction="row" justifyContent="center">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={trezorSafeChecked}
                                  checkedIcon={<CheckBoxOutlinedIcon />}
                                  onChange={() => {
                                    setTrezorSafeChecked(prev => !prev);
                                  }}
                                  ref={undefined}
                                  sx={{
                                    '&&': {
                                      color: '#373741',
                                    },
                                    p: 0.5,
                                    ml: 1,
                                  }}
                                />
                              }
                              label={
                                <Typography fontSize={12} fontWeight={700} textAlign="center">
                                  Claiming via Trezor or Safe?
                                </Typography>
                              }
                              labelPlacement="end"
                              sx={{ mt: 2 }}
                            />
                          </Stack>
                          <Stack columnGap={1} direction="row">
                            <LoadingButton
                              disabled={currentPeriod !== 'Active'}
                              fullWidth
                              loading={isClaiming || isSigning}
                              onClick={() => {
                                if (trezorSafeChecked) {
                                  claim();
                                } else if (!signature) {
                                  signMessage();
                                } else {
                                  claimWSignature();
                                }
                              }}
                              sx={{
                                mt: 3,
                                ...((trezorSafeChecked || signature) && {
                                  background: '#13005A',
                                  color: 'white',
                                }),
                              }}
                              variant="contained"
                            >
                              {!trezorSafeChecked && !signature ? 'Sign' : 'Claim'}
                            </LoadingButton>
                          </Stack>
                        </Stack>
                      )
                    }
                    sx={{ mx: 'auto' }}
                    tokenClaimData={tokenClaimData}
                  />
                </Stack>
              </CardContainer>
            )}
          </>
        )}
      </Web3Route>
    </Stack>
  );
};
