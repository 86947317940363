import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  BoxProps,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import Stack from '@mui/system/Stack';
import { CardContainer } from 'components/CardContainer';
import MaxButton from 'components/MaxButton';
import MaximizableAmount from 'components/MaximizableAmount';
import VaultSelectDrawer from 'components/VaultSelectDrawer';
import { ConditionalConnectButton } from 'components/Web3Route';
import { useAlerts } from 'contexts/AlertsContext';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { DistData, StakingContractInfo, useStakingInfo } from 'contexts/StakingInfoContext';
import {
  commify,
  formatWithPrecision,
  getStakingContracts,
  getTimeLeft,
  SECONDS_IN_YEAR,
  supportedEthChainId,
  WEEKS_IN_YEAR,
} from 'core/utils';
import { useAmount } from 'hooks/useAmount';
import { useContractRead } from 'hooks/useContractRead';
import { useLastSelectedVault } from 'hooks/useLastSelectedVault';
import {
  Dispatch,
  Fragment,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { VaultConfig } from 'types';
import { Address, erc20Abi, formatUnits } from 'viem';
import { useAccount } from 'wagmi';

import ActionButton from './ActionButton';

export default function StepStake({
  setActiveStep,
  vaults,
}: BoxProps & { vaults: VaultConfig[]; setActiveStep: Dispatch<SetStateAction<number>> }) {
  const { lastSelectedVaultId: vaultId, setLastSelectedVaultId } = useLastSelectedVault();
  const { address } = useAccount();
  const vaultConfig = window.appConfig?.vaults?.[vaultId];

  useEffect(() => {
    if (!vaults?.find(cur => vaultId === cur.id)) {
      setLastSelectedVaultId(vaults?.[0]?.id);
    }
  }, [setLastSelectedVaultId, vaultId, vaults]);

  const { setStakingInfo, stakingInfo } = useStakingInfo();
  const stakingContractsAddrs = useMemo(() => getStakingContracts(vaultId), [vaultId]);
  const stakingContractsInfo = useMemo(
    () => stakingContractsAddrs?.map(contractAddr => stakingInfo?.[vaultId]?.[contractAddr]),
    [vaultId, stakingContractsAddrs, stakingInfo]
  );
  const location = useLocation();
  const [selectedStakingContract, setSelectedStakingContract] = useState<StakingContractInfo>(
    stakingContractsInfo?.find(
      contract => contract?.stakedTokenAddr === location.state?.stakedTokenAddr
    ) || stakingContractsInfo?.[0]
  );

  useEffect(() => {
    // set to first contract if user changes vault
    setSelectedStakingContract(stakingContractsInfo?.[0]);
  }, [stakingContractsInfo]);

  const stakingContractInfo =
    stakingInfo?.[vaultId]?.[selectedStakingContract?.stakingContractAddr]; // Fresh data from context instead of component state

  const { showReadContractError } = useAlerts();
  const { data: allowance, refetch: refetchAllowance } = useContractRead<bigint>({
    address: stakingContractInfo?.stakedTokenAddr,
    abi: erc20Abi,
    functionName: 'allowance',
    chainId: supportedEthChainId,
    args:
      address && stakingContractInfo
        ? [address, stakingContractInfo?.stakingContractAddr]
        : undefined,
    enabled: Boolean(address && stakingContractInfo),
    onError: (err: Error) => {
      showReadContractError('allowance', err);
    },
  });

  const { refetchAllVaultTokens, tokens } = useStakedTokens();
  const erc20Balance = tokens?.[selectedStakingContract?.stakedTokenAddr || '0x'];

  const { amountBN, amountRaw, setAmountRaw } = useAmount(erc20Balance?.decimals || 18);

  const handleSelectToken = (event: SelectChangeEvent) => {
    const selectedToken = event?.target?.value;
    const selectedContract = stakingContractsInfo.find(
      cur => cur?.stakedTokenSymbol === selectedToken
    );

    setSelectedStakingContract(selectedContract || stakingContractsInfo?.[0]);
  };

  useEffect(() => {
    refetchAllVaultTokens(vaultId);
  }, [refetchAllVaultTokens, vaultId]);

  const { prices } = useStakedTokens();

  const poolTvl = stakingContractInfo?.operatorList?.reduce(
    (tvl, operator) => tvl + operator?.totalVotingStake,
    0n
  );

  const totalStakedBalance = Object.values(stakingContractInfo?.votingStakeBalances || {})?.reduce(
    (sum, bal) => bal + sum,
    0n
  );

  const stakingStartTime = stakingContractInfo?.stakingStartTime || 0;

  const [stakeCountdown, setStakeCountdown] = useState('');

  useEffect(() => {
    if (stakingStartTime * 1000 > Date.now() && !stakingContractInfo?.stakingStarted) {
      const countdown = setInterval(() => {
        setStakeCountdown(getTimeLeft(new Date(stakingStartTime * 1000)));
      }, 1000);

      return () => clearInterval(countdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakingStartTime, stakingContractInfo?.stakingStarted]);

  const updateStakingStartedStatus = useCallback(() => {
    const currentTime = Date.now();

    setStakingInfo(prev => ({
      ...prev,
      [vaultId]: {
        ...prev?.[vaultId],
        [stakingContractInfo?.stakingContractAddr]: {
          ...prev?.[vaultId]?.[stakingContractInfo?.stakingContractAddr],
          stakingStarted: currentTime >= stakingStartTime * 1000,
          distributionMap: Object.entries(stakingContractInfo?.allDistributions || {}).reduce(
            (acc, [distId, dist]) => {
              const currentTime = Date.now();

              if (dist?.endTime * 1000 <= currentTime || dist?.startTime * 1000 > currentTime) {
                return acc;
              }

              return {
                ...acc,
                [distId]: dist,
              };
            },
            {} as Record<number, DistData>
          ),
        },
      },
    }));
  }, [vaultId, setStakingInfo, stakingContractInfo, stakingStartTime]);

  useEffect(() => {
    if (stakeCountdown === '0s' && !stakingContractInfo?.stakingStarted) {
      updateStakingStartedStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakeCountdown, stakingContractInfo?.stakingStarted]);

  useEffect(() => {
    updateStakingStartedStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [promptMaxApproval, setPromptMaxApproval] = useState(true);

  return (
    <>
      <MaximizableAmount
        endAdornment={
          <FormControl sx={{ minWidth: { xs: 90, lg: 120 } }}>
            {stakingContractsInfo?.length > 1 ? (
              <Select
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={handleSelectToken}
                sx={{
                  '& .MuiSelect-select.MuiInputBase-input': {
                    textAlign: 'right',
                    textOverflow: 'clip',
                    padding: 0,
                    pl: 1,
                    pr: { xs: 10, sm: 6, lg: 9 },
                  },
                  color: theme => theme.colors.schema.primary,
                  fontWeight: 900,
                  fontSize: { xs: 14, md: 20, lg: 24 },
                }}
                value={selectedStakingContract?.stakedTokenSymbol}
              >
                {stakingContractsInfo?.map(contract => (
                  <MenuItem key={contract?.stakedTokenAddr} value={contract?.stakedTokenSymbol}>
                    {contract?.stakedTokenSymbol}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography
                sx={{
                  fontSize: { xs: 14, sm: 18 },
                  color: theme => theme.colors.schema.primary,
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  columnGap: 0.75,
                }}
              >
                <img
                  src={
                    window.appConfig?.erc20Info?.[
                      stakingContractsInfo?.[0]?.stakedTokenAddr as Address
                    ]?.iconUrl
                  }
                  style={{ width: 26 }}
                />
                {stakingContractsInfo?.[0]?.stakedTokenSymbol}
              </Typography>
            )}
          </FormControl>
        }
        fullWidth
        setAmountRaw={setAmountRaw}
        sx={{ mt: 1 }}
        tokenSymbol={erc20Balance?.symbol}
        value={amountRaw}
      />
      <Stack direction="row" flexWrap="wrap" justifyContent="space-between" mt={2}>
        <Typography variant="subtitle1">
          BALANCE:&nbsp;
          <Typography component="span" variant="subtitle1">
            {commify(erc20Balance?.formattedBalance) || '-'}
          </Typography>
          <Typography component="span" variant="subtitle1">
            &nbsp;{erc20Balance?.symbol}
          </Typography>
        </Typography>
        <MaxButton
          onClick={() => {
            setAmountRaw(formatUnits(erc20Balance?.balance, erc20Balance?.decimals));
          }}
        />
      </Stack>
      {vaultId !== 'mach_alpha' && (
        <>
          <Typography fontWeight={900} my={1.5} variant="h5">
            VAULT
          </Typography>
          <VaultSelectDrawer vaults={vaults} />
        </>
      )}

      <Grid
        container
        justifyContent="space-between"
        mt={2}
        p={2}
        rowGap={{ xs: 1, sm: 4 }}
        sx={{
          fontWeight: 900,
          background: 'linear-gradient(154.71deg, #FFFFFF 0%, #F7F7F7 100%)',
        }}
      >
        <RewardsAPY selectedContract={stakingContractInfo} vaultId={vaultId} />
        <Grid item mt={{ xs: 2, sm: 0 }} sm={5} xs={12}>
          <Typography>POOL TVL</Typography>
        </Grid>
        <Grid item sm={7} textAlign={{ xs: 'left', sm: 'right' }} xs={12}>
          <Typography>
            {commify(formatWithPrecision(poolTvl || 0n))}{' '}
            {selectedStakingContract?.stakedTokenSymbol} ($
            {commify(
              (
                Number(formatWithPrecision(poolTvl || 0n)) *
                  prices?.[selectedStakingContract?.stakedTokenAddr || '0x'] || 0
              )?.toFixed(0)
            )}
            )
          </Typography>
        </Grid>
        <Grid item mt={{ xs: 2, sm: 0 }} sm={5} xs={12}>
          <Typography>YOUR STAKED BALANCE</Typography>
        </Grid>
        <Grid item sm={7} textAlign={{ xs: 'left', sm: 'right' }} xs={12}>
          <Typography>
            {commify(formatWithPrecision(totalStakedBalance))}{' '}
            {selectedStakingContract?.stakedTokenSymbol} ($
            {commify(
              (
                Number(formatWithPrecision(totalStakedBalance || 0n)) *
                  prices?.[selectedStakingContract?.stakedTokenAddr || '0x'] || 0
              )?.toFixed(2)
            )}
            )
          </Typography>
        </Grid>
      </Grid>
      {stakingStartTime * 1000 > Date.now() && (
        <CardContainer
          sx={{
            my: 2,
            border: '1px solid #C4AA7D',
            bgcolor: '#FEFD98',
            maxWidth: '550px',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>You&apos;re early! Staking begins in {stakeCountdown}</Typography>
        </CardContainer>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={promptMaxApproval}
            checkedIcon={<CheckBoxOutlinedIcon />}
            onChange={() => {
              setPromptMaxApproval(prev => !prev);
            }}
            ref={undefined}
            sx={{
              '&&': {
                color: '#373741',
              },
              p: 0.5,
              ml: 1,
            }}
          />
        }
        label={
          <Stack alignItems="center" direction="row" gap={1}>
            <Typography fontSize={12} fontWeight={700}>
              Use max token approval
            </Typography>
            <Tooltip
              placement="top"
              title="Save gas on future transactions when you maximize token approval amount"
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </Stack>
        }
        labelPlacement="end"
        sx={{ mt: 2 }}
      />
      <Stack flexDirection="row" mt={{ md: 4, xs: 2 }}>
        <ConditionalConnectButton chainId={supportedEthChainId}>
          {!!vaultConfig &&
            (erc20Balance?.balance !== undefined ? (
              <ActionButton
                allowance={allowance || 0n}
                amount={amountBN || 0n}
                balance={erc20Balance?.balance}
                onStaked={() => setActiveStep(1)}
                producerNode={stakingContractInfo?.operatorAddresses?.[0]} // assume 1 operator per vault
                promptMaxApproval={promptMaxApproval}
                refetchAllowance={refetchAllowance}
                selectedStakingContract={stakingContractInfo}
                vaultId={vaultId}
              />
            ) : null)}
        </ConditionalConnectButton>
      </Stack>
    </>
  );
}

function RewardsAPY({
  selectedContract,
  vaultId,
}: PropsWithChildren & { selectedContract: StakingContractInfo; vaultId: string }) {
  const { prices } = useStakedTokens();
  const distributions = Object.values(selectedContract?.allDistributions || {})?.filter(
    dist => dist.endTime * 1000 > Date.now()
  );

  return (
    <Fragment>
      {/* <Grid
      alignItems="center"
      container
      justifyContent="center"
      rowGap={1}
      sx={{
        py: 1,
        px: 3,
        fontWeight: 900,
      }}
    > */}
      {!window.appConfig?.launchPoolTokens?.[vaultId] && (
        <Grid alignItems="center" container item sm={5} textAlign="left" xs={12}>
          <Typography>REWARDS</Typography>
        </Grid>
      )}
      {window.appConfig?.launchPoolTokens?.[vaultId] ? (
        <Grid alignItems="center" container gap={1} justifyContent={'center'}>
          <img
            src={window.appConfig?.launchPoolTokens?.[vaultId]?.[0]?.iconUrl}
            style={{ width: 30 }}
          />
          Earn {window.appConfig?.launchPoolTokens?.[vaultId]?.[0]?.tokenSymbol}
        </Grid>
      ) : (
        distributions?.map(dist => {
          const distEpy = dist.eps * SECONDS_IN_YEAR;
          const tokenRewardsUsd =
            Number(formatWithPrecision(distEpy || 0n)) * (prices?.[dist.rewardToken] || 0);
          const globalVotingStakeUsd =
            Number(formatWithPrecision(selectedContract?.globalVotingStake || 0n)) *
            (prices?.[selectedContract?.stakedTokenAddr as Address] || 0);
          const tokenAPR = globalVotingStakeUsd > 0 ? tokenRewardsUsd / globalVotingStakeUsd : 0;
          const tokenAPY = (1 + tokenAPR / WEEKS_IN_YEAR) ** WEEKS_IN_YEAR - 1;
          const tokenAPYPercentage = tokenAPY * 100;

          return (
            <Grid
              alignItems="center"
              container
              flexWrap="nowrap"
              gap={1}
              item
              justifyContent={{ sm: 'flex-end', xs: 'flex-start' }}
              key={dist.id}
              sm={7 / distributions.length}
              sx={{
                color: '#74BA94',
                fontSize: '24px',
              }}
              xs={12}
            >
              {dist.startTime * 1000 > Date.now()
                ? '(Upcoming)'
                : `APY ${
                    tokenAPYPercentage === 0 ? '-' : commify(tokenAPYPercentage?.toFixed(1))
                  }%`}
            </Grid>
          );
        })
      )}
    </Fragment>
  );
}
