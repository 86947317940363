import { Box, Stack, StackProps, Typography } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { FC, ReactNode } from 'react';
import { useAccount, useChainId } from 'wagmi';

import EnsureNetworkButton from './EnsureNetworkButton';

export const Web3Route: FC<
  { bypass?: boolean; chainId?: number; wrongNetworkMessage?: ReactNode } & StackProps
> = ({
  bypass = false,
  chainId,
  children,
  wrongNetworkMessage = (
    <>
      <Typography variant="h4">Wallet not connected, or wrong network selected</Typography>
      <Typography variant="h6">
        To continue, please connect your wallet or switch to the correct network
      </Typography>
    </>
  ),
  ...rest
}) => {
  const currentChainId = useChainId();
  const { isConnected } = useAccount();

  const isRouteAccessible =
    bypass || (chainId ? currentChainId === chainId && isConnected : isConnected);

  return isRouteAccessible ? (
    children
  ) : (
    <Stack
      alignItems="center"
      flexGrow={1}
      height={'100%'}
      justifyContent="center"
      rowGap={2}
      {...rest}
    >
      {wrongNetworkMessage}
      <Box mt={1}>
        <ConditionalConnectButton bypass={bypass} chainId={chainId} />
      </Box>
    </Stack>
  );
};

export const ConditionalConnectButton: FC<
  {
    bypass?: boolean;
    chainId?: number;
    hideHelperText?: boolean;
    buttonEndAdornment?: ReactNode;
  } & StackProps
> = ({
  buttonEndAdornment,
  bypass = false,
  chainId,
  children,
  hideHelperText = false,
  sx,
  ...rest
}) => {
  const { chainId: currentChainId, isConnected } = useAccount();

  const isRouteAccessible =
    bypass || (chainId ? currentChainId === chainId && isConnected : isConnected);

  return isRouteAccessible ? (
    children
  ) : (
    <Stack
      alignItems="center"
      flexGrow={1}
      height={'100%'}
      justifyContent="center"
      rowGap={2}
      sx={{
        width: '100%',
        '&& button': {
          background: isConnected ? '#FF494A' : 'black',
        },
        '&& button:hover': {
          filter: 'invert(0.08)',
        },
        '&& button .MuiTypography-root': {
          color: 'white',
        },
        ...sx,
      }}
      {...rest}
    >
      {chainId ? (
        <>
          {isConnected && !hideHelperText && (
            <Typography textAlign="center">Please switch to chain ID: {chainId}</Typography>
          )}
          <EnsureNetworkButton buttonEndAdornment={buttonEndAdornment} targetId={chainId} />
        </>
      ) : (
        <ConnectButton />
      )}
    </Stack>
  );
};
