import { readContract } from '@wagmi/core';
import STAKE_ABI from 'core/contracts/STAKE_ABI';
import { getStakingContracts, supportedEthChainId } from 'core/utils';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Operator } from 'types';
import { Address, erc20Abi, RpcErrorType } from 'viem';
import { useAccount } from 'wagmi';

import { useAlerts } from './AlertsContext';
import { useWagmi } from './WagmiContext';

export interface Cooldown {
  timestamp: number;
  amount: bigint;
}

export interface DistData {
  id: number;
  eps: bigint;
  rewardToken: Address;
  rewardVault: Address;
  startTime: number;
  endTime: number;
}

export interface StakingContractInfo {
  stakingContractAddr: Address;
  cooldownSeconds?: number;
  totalOperators?: bigint;
  operatorAddresses: readonly Address[];
  operatorList?: Operator[];
  votingStakeBalances?: Record<string, bigint>;
  cooldowns?: Record<string, Cooldown>;
  rewards?: Record<string, Record<Address, bigint>>;
  stakedTokenAddr?: Address;
  stakedTokenSymbol?: string;
  distributionMap?: Record<number, DistData>;
  allDistributions?: Record<number, DistData>;
  stakingStartTime?: number;
  stakingStarted?: boolean;
  globalVotingStake?: bigint;
}
interface IStakingInfoContext {
  stakingInfo: Record<string, Record<Address, StakingContractInfo>>;
  setStakingInfo: Dispatch<SetStateAction<Record<string, Record<Address, StakingContractInfo>>>>;
  isAllVaultInfoFetched: boolean;
  refetchNodeCooldownInfo: (
    vaultId: string,
    stakingContractAddr: Address,
    operatorAddr: Address
  ) => Promise<void>;
  refetchNodeStakedBalance: (
    vaultId: string,
    stakingContractAddr: Address,
    operatorAddr: Address
  ) => Promise<void>;
  refetchNodeUnclaimedRewards: (
    vaultId: string,
    stakingContractAddr: Address,
    operatorAddr: Address
  ) => Promise<void>;
  refetchContractGlobalVotingStake: (contractAddr: Address) => Promise<void>;
  refetchVaultUserInfo: (vaultId: string) => Promise<void>;
  refetchAllVaultUserInfo: () => Promise<void>;
  refetchStakingContractInfo: (vaultId: string) => Promise<void>;
  refetchAllStakingContractInfo: () => Promise<void[]>;
}

const vaultsConfigs = window.appConfig?.vaults || {};
const defaultStakingInfoValues = Object.keys(vaultsConfigs)?.reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: {},
  }),
  {}
);
const StakingInfoContext = createContext<IStakingInfoContext>({
  stakingInfo: defaultStakingInfoValues,
} as IStakingInfoContext);

export const useStakingInfo = () => useContext(StakingInfoContext);

export const StakingInfoContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { showReadContractError } = useAlerts();
  const [stakingInfo, setStakingInfo] = useState(
    defaultStakingInfoValues as Record<string, Record<string, StakingContractInfo>>
  );
  const [isAllVaultInfoFetched, setIsAllVaultInfoFetched] = useState(false);
  const [isAccountInfoFetched, setIsAccountInfoFetched] = useState(false);

  const { chains, config } = useWagmi();

  const { address } = useAccount();

  const refetchNodeCooldownInfo = useCallback(
    async (vaultId: string, stakingContractAddr: Address, operatorAddr: Address) => {
      if (address) {
        try {
          const cooldownInfo = await readContract(config, {
            abi: STAKE_ABI,
            address: stakingContractAddr,
            functionName: 'cooldowns',
            args: [operatorAddr, address],
            chainId: supportedEthChainId,
          });

          setStakingInfo(prev => ({
            ...prev,
            [vaultId]: {
              ...prev?.[vaultId],
              [stakingContractAddr]: {
                ...prev?.[vaultId]?.[stakingContractAddr],
                cooldowns: {
                  ...prev?.[vaultId]?.[stakingContractAddr]?.cooldowns,
                  [operatorAddr]: { timestamp: cooldownInfo?.[0], amount: cooldownInfo?.[1] },
                },
              },
            },
          }));
        } catch (_err) {
          const err = _err as RpcErrorType;

          showReadContractError('Fetch node cooldown info', err);

          console.debug('config: ', config);
        }
      }
    },
    [address, config, showReadContractError]
  );

  const refetchNodeStakedBalance = useCallback(
    async (vaultId: string, stakingContractAddr: Address, operatorAddr: Address) => {
      if (address) {
        try {
          const [balance, operatorTotalSupply, operatorTotalCooldownAmount] = await Promise.all([
            readContract(config, {
              abi: STAKE_ABI,
              address: stakingContractAddr,
              functionName: 'votingStake',
              args: [address, operatorAddr],
              chainId: supportedEthChainId,
            }),
            readContract(config, {
              abi: STAKE_ABI,
              address: stakingContractAddr,
              functionName: 'totalSupply',
              args: [operatorAddr],
              chainId: supportedEthChainId,
            }),
            readContract(config, {
              abi: STAKE_ABI,
              address: stakingContractAddr,
              functionName: 'totalCooldownAmounts',
              args: [operatorAddr],
              chainId: supportedEthChainId,
            }),
          ]);

          setStakingInfo(prev => ({
            ...prev,
            [vaultId]: {
              ...prev?.[vaultId],
              [stakingContractAddr]: {
                ...prev?.[vaultId]?.[stakingContractAddr],
                votingStakeBalances: {
                  ...prev?.[vaultId]?.[stakingContractAddr]?.votingStakeBalances,
                  [operatorAddr]: balance,
                },
                operatorList: prev?.[vaultId]?.[stakingContractAddr]?.operatorList?.map(operator =>
                  operator?.address === operatorAddr
                    ? {
                        ...operator,
                        totalVotingStake: operatorTotalSupply - (operatorTotalCooldownAmount || 0n),
                      }
                    : operator
                ),
              },
            },
          }));
        } catch (_err) {
          const err = _err as RpcErrorType;

          showReadContractError('Fetch node staked balance', err);

          console.debug('config: ', config);
        }
      }
    },
    [address, config, showReadContractError]
  );

  const refetchNodeUnclaimedRewards = useCallback(
    async (vaultId: string, stakingContractAddr: Address, operatorAddr: Address) => {
      if (address) {
        try {
          const distMap = stakingInfo?.[vaultId]?.[stakingContractAddr]?.distributionMap;
          const [accruedRewards, rewardsBalances] = await Promise.all([
            Promise.all(
              Object.keys(distMap || {}).map(distId =>
                readContract(config, {
                  abi: STAKE_ABI,
                  address: stakingContractAddr,
                  functionName: 'getAccruedRewards',
                  args: [Number(distId), address, operatorAddr],
                  chainId: supportedEthChainId,
                })
              )
            ),
            Promise.all(
              Object.keys(distMap || {}).map(distId =>
                readContract(config, {
                  abi: STAKE_ABI,
                  address: stakingContractAddr,
                  functionName: 'rewardBalance',
                  args: [Number(distId), operatorAddr, address],
                  chainId: supportedEthChainId,
                })
              )
            ),
          ]);

          const unclaimedRewards = Object.values(distMap || {}).reduce(
            (acc, dist, distIndex) => ({
              ...acc,
              [dist?.rewardToken]:
                BigInt(accruedRewards?.[distIndex]) + BigInt(rewardsBalances?.[distIndex]),
            }),
            {}
          );

          setStakingInfo(prev => ({
            ...prev,
            [vaultId]: {
              ...prev?.[vaultId],
              [stakingContractAddr]: {
                ...prev?.[vaultId]?.[stakingContractAddr],
                rewards: {
                  ...prev?.[vaultId]?.[stakingContractAddr]?.rewards,
                  [operatorAddr]: unclaimedRewards,
                },
              },
            },
          }));
        } catch (_err) {
          const err = _err as RpcErrorType;

          showReadContractError('Fetch node rewards', err);

          console.debug('config: ', config);
        }
      }
    },
    [address, config, stakingInfo, showReadContractError]
  );

  const refetchVaultUserInfo = useCallback(
    async (vaultId: string) => {
      const contracts = getStakingContracts(vaultId);

      await Promise.all(
        contracts?.map(async contractAddr => {
          const distMap = stakingInfo?.[vaultId]?.[contractAddr]?.distributionMap;
          const operatorAddresses = stakingInfo?.[vaultId]?.[contractAddr]?.operatorAddresses || [];

          if (vaultId && address && operatorAddresses?.length) {
            const [balances, cooldowns, accruedRewards, rewardsBalances] = await Promise.all([
              Promise.all(
                operatorAddresses.map(operatorAddr =>
                  readContract(config, {
                    abi: STAKE_ABI,
                    address: contractAddr,
                    functionName: 'votingStake',
                    args: [address, operatorAddr],
                    chainId: supportedEthChainId,
                  })
                )
              ),
              Promise.all(
                operatorAddresses.map(operatorAddr =>
                  readContract(config, {
                    abi: STAKE_ABI,
                    address: contractAddr,
                    functionName: 'cooldowns',
                    args: [operatorAddr, address],
                    chainId: supportedEthChainId,
                  })
                )
              ),
              Promise.all(
                Object.keys(distMap || {}).map(distId =>
                  Promise.all(
                    operatorAddresses.map(operatorAddr =>
                      readContract(config, {
                        abi: STAKE_ABI,
                        address: contractAddr,
                        functionName: 'getAccruedRewards',
                        args: [Number(distId), address, operatorAddr],
                        chainId: supportedEthChainId,
                      })
                    )
                  )
                )
              ),
              Promise.all(
                Object.keys(distMap || {}).map(distId =>
                  Promise.all(
                    operatorAddresses.map(operatorAddr =>
                      readContract(config, {
                        abi: STAKE_ABI,
                        address: contractAddr,
                        functionName: 'rewardBalance',
                        args: [Number(distId), operatorAddr, address],
                        chainId: supportedEthChainId,
                      })
                    )
                  )
                )
              ),
            ]);

            const votingStakeBalances = balances?.reduce(
              (acc, balance, index) => ({
                ...acc,
                [operatorAddresses[index]]: balance,
              }),
              {} as Record<string, bigint>
            );

            const cooldownData = cooldowns.reduce((acc, cur, index) => {
              const result = cur as [number, bigint];

              return {
                ...acc,
                [operatorAddresses?.[index]]: {
                  timestamp: result?.[0],
                  amount: result?.[1],
                },
              };
            }, {} as Record<string, Cooldown>);

            const vaultRewards = operatorAddresses?.reduce((acc, operatorAddr, index) => {
              const accrued = Object.values(distMap || {})?.reduce(
                (acc, dist, distIndex) => ({
                  ...acc,
                  [dist.rewardToken]: accruedRewards?.[distIndex]?.[index],
                }),
                {} as Record<Address, bigint>
              );
              const balance = Object.values(distMap || {})?.reduce(
                (acc, dist, distIndex) => ({
                  ...acc,
                  [dist.rewardToken]: rewardsBalances?.[distIndex]?.[index],
                }),
                {} as Record<Address, bigint>
              );
              const totalRewards = (Object.keys(balance || {}) as Address[])?.reduce(
                (acc, tokenAddr) => ({
                  ...acc,
                  [tokenAddr]: BigInt(balance[tokenAddr] || 0n) + (accrued[tokenAddr] || 0n),
                }),
                {} as Record<Address, bigint>
              );

              return {
                ...acc,
                [operatorAddr]: totalRewards,
              };
            }, {} as Record<string, Record<Address, bigint>>);

            setStakingInfo(prev => ({
              ...prev,
              [vaultId]: {
                ...prev?.[vaultId],
                [contractAddr]: {
                  ...prev?.[vaultId]?.[contractAddr],
                  votingStakeBalances:
                    votingStakeBalances || prev?.[vaultId]?.[contractAddr]?.votingStakeBalances,
                  cooldowns: cooldownData || prev?.[vaultId]?.[contractAddr]?.cooldowns,
                  rewards: vaultRewards || prev?.[vaultId]?.[contractAddr]?.rewards,
                },
              },
            }));
          }
        })
      );
    },
    [address, config, stakingInfo]
  );

  const refetchContractGlobalVotingStake = useCallback(
    async (contractAddr: Address) => {
      const entry = Object.entries(stakingInfo || {}).find(([, contractMap]) => {
        return Object.keys(contractMap || {}).find(key => key === contractAddr);
      });
      const vaultId = entry?.[0];

      try {
        if (!vaultId) throw Error('Unable to find vault ID');

        const [totalSupply, totalCooldownAmount] = await Promise.all([
          readContract(config, {
            abi: STAKE_ABI,
            address: contractAddr,
            functionName: 'totalSupply',
            chainId: supportedEthChainId,
          }),
          readContract(config, {
            abi: STAKE_ABI,
            address: contractAddr,
            functionName: 'totalCooldownAmount',
            chainId: supportedEthChainId,
          }),
        ]);

        const globalVotingStake = totalSupply - totalCooldownAmount;

        setStakingInfo(prev => ({
          ...prev,
          [vaultId]: {
            ...prev?.[vaultId],
            [contractAddr]: {
              ...prev?.[vaultId]?.[contractAddr],
              globalVotingStake,
            },
          },
        }));
      } catch (_err) {
        const err = _err as RpcErrorType;

        showReadContractError(`Failed to fetch contract ${contractAddr}`, err);

        console.debug('config: ', config);
      }
    },
    [config, showReadContractError, stakingInfo]
  );

  const refetchStakingContractInfo = useCallback(
    async (vaultId: string) => {
      const contracts = getStakingContracts(vaultId);

      await Promise.all(
        contracts?.map(async contractAddr => {
          const [
            cooldownSeconds,
            totalOperators,
            stakedTokenAddr,
            totalDistributions,
            totalSupply,
            totalCooldownAmount,
            stakingStartTimestamp,
          ] = await Promise.all([
            readContract(config, {
              abi: STAKE_ABI,
              address: contractAddr,
              functionName: 'cooldownSeconds',
              chainId: supportedEthChainId,
            }),
            readContract(config, {
              abi: STAKE_ABI,
              address: contractAddr,
              functionName: 'totalOperators',
              chainId: supportedEthChainId,
            }),
            readContract(config, {
              abi: STAKE_ABI,
              address: contractAddr,
              chainId: supportedEthChainId,
              functionName: 'stakedToken',
            }),
            readContract(config, {
              abi: STAKE_ABI,
              address: contractAddr,
              chainId: supportedEthChainId,
              functionName: 'totalDistributions',
            }),
            readContract(config, {
              abi: STAKE_ABI,
              address: contractAddr,
              chainId: supportedEthChainId,
              functionName: 'totalSupply',
            }),
            readContract(config, {
              abi: STAKE_ABI,
              address: contractAddr,
              chainId: supportedEthChainId,
              functionName: 'totalCooldownAmount',
            }),
            readContract(config, {
              abi: STAKE_ABI,
              address: contractAddr,
              chainId: supportedEthChainId,
              functionName: 'stakingStartTimestamp',
            }),
          ]);

          const globalVotingStake = totalSupply - totalCooldownAmount;

          const allDistIds = Array(totalDistributions)
            .fill(undefined)
            .map((_, index) => index + 1);

          const [operatorAddresses, distributionsData, stakedTokenSymbol] = await Promise.all([
            readContract(config, {
              address: contractAddr,
              abi: STAKE_ABI,
              functionName: 'queryOperators',
              chainId: supportedEthChainId,
              args: [0n, totalOperators ?? 0n],
            }),
            Promise.all(
              allDistIds.map(id =>
                readContract(config, {
                  address: contractAddr,
                  abi: STAKE_ABI,
                  chainId: supportedEthChainId,
                  functionName: 'distributions',
                  args: [id],
                })
              )
            ),
            readContract(config, {
              address: stakedTokenAddr,
              abi: erc20Abi,
              chainId: supportedEthChainId,
              functionName: 'symbol',
            }),
          ]);

          const [operatorFees, operatorTotalSupplies, operatorCooldownAmounts, frozenOperators] =
            await Promise.all([
              Promise.all(
                operatorAddresses?.map(operatorAddr =>
                  readContract(config, {
                    address: contractAddr,
                    abi: STAKE_ABI,
                    functionName: 'operatorFee',
                    chainId: supportedEthChainId,
                    args: [operatorAddr],
                  })
                )
              ),
              Promise.all(
                operatorAddresses?.map(operatorAddr =>
                  readContract(config, {
                    address: contractAddr,
                    abi: STAKE_ABI,
                    functionName: 'totalSupply',
                    chainId: supportedEthChainId,
                    args: [operatorAddr],
                  })
                )
              ),
              Promise.all(
                operatorAddresses?.map(operatorAddr =>
                  readContract(config, {
                    address: contractAddr,
                    abi: STAKE_ABI,
                    functionName: 'totalCooldownAmounts',
                    chainId: supportedEthChainId,
                    args: [operatorAddr],
                  })
                )
              ),
              Promise.all(
                operatorAddresses?.map(operatorAddr =>
                  readContract(config, {
                    address: contractAddr,
                    abi: STAKE_ABI,
                    functionName: 'isFrozenOperator',
                    chainId: supportedEthChainId,
                    args: [operatorAddr],
                  })
                )
              ),
            ]);

          const unfrozenCooldownAmounts = operatorCooldownAmounts?.filter(
            (_, index) => !frozenOperators?.[index]
          );
          const operatorFeeBps = operatorFees
            ?.filter((_, index) => !frozenOperators?.[index])
            ?.map(operator => operator?.[1]);
          const operatorTotalVotingStake = operatorTotalSupplies
            ?.filter((_, index) => !frozenOperators?.[index])
            ?.map((total, index) => total - (unfrozenCooldownAmounts?.[index] || 0n));

          const allDistributions = distributionsData.reduce((acc, dist, index) => {
            const [eps, rewardToken, rewardVault, startTime, endTime] = dist;

            return {
              ...acc,
              [allDistIds?.[index]]: {
                id: allDistIds?.[index],
                eps,
                rewardToken,
                rewardVault,
                startTime,
                endTime,
              },
            };
          }, {} as Record<number, DistData>);

          const activeDistributions = Object.entries(allDistributions || {}).reduce(
            (acc, [distId, dist]) => {
              const currentTime = Date.now();

              if (dist?.endTime * 1000 <= currentTime || dist?.startTime * 1000 > currentTime) {
                return acc;
              }

              return {
                ...acc,
                [distId]: dist,
              };
            },
            {} as Record<number, DistData>
          );

          const operatorList: Operator[] =
            operatorAddresses
              ?.filter((_, index) => !frozenOperators?.[index])
              ?.map((operatorAddress, index) => ({
                id: BigInt(operatorAddress)?.toString(),
                address: operatorAddress,
                feeBps: operatorFeeBps?.[index],
                totalVotingStake: operatorTotalVotingStake?.[index],
              })) || [];

          setStakingInfo(prev => ({
            ...prev,
            [vaultId]: {
              ...prev?.[vaultId],
              [contractAddr]: {
                ...prev?.[vaultId]?.[contractAddr],
                stakingContractAddr: contractAddr,
                stakedTokenAddr,
                stakedTokenSymbol,
                cooldownSeconds,
                totalOperators,
                operatorAddresses: operatorAddresses?.filter(
                  (_, index) => !frozenOperators?.[index]
                ),
                operatorList,
                distributionMap: activeDistributions,
                allDistributions,
                stakingStartTime: stakingStartTimestamp,
                stakingStarted: Date.now() >= stakingStartTimestamp * 1000,
                globalVotingStake,
              },
            },
          }));
        })
      );
    },
    [config]
  );

  console.debug('stakingInfo: ', stakingInfo);

  const refetchAllStakingContractInfo = useCallback(() => {
    return Promise.all(
      Object.keys(stakingInfo)?.map(async vaultId => await refetchStakingContractInfo(vaultId))
    );
  }, [refetchStakingContractInfo, stakingInfo]);

  useEffect(() => {
    (async () => {
      setIsAllVaultInfoFetched(false);
      setIsAccountInfoFetched(false);

      try {
        await refetchAllStakingContractInfo();
        setIsAllVaultInfoFetched(true);
      } catch (_err) {
        const err = _err as RpcErrorType;

        console.error('Error fetching all vault info', err);
        console.debug('config: ', config);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chains]);

  useEffect(() => {
    setIsAccountInfoFetched(false);
  }, [address]);

  const refetchAllVaultUserInfo = useCallback(async () => {
    await Promise.all(
      Object.keys(stakingInfo)?.map(async vaultId => {
        await refetchVaultUserInfo(vaultId);
      })
    );
  }, [refetchVaultUserInfo, stakingInfo]);

  useEffect(() => {
    (async () => {
      try {
        if (isAllVaultInfoFetched && !isAccountInfoFetched && address) {
          await refetchAllVaultUserInfo();
          setIsAccountInfoFetched(true);
        }
      } catch (_err) {
        const err = _err as RpcErrorType;

        console.debug('config: ', config);
        console.error('Error fetching all vault user info', err);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isAllVaultInfoFetched, isAccountInfoFetched]);

  return (
    <StakingInfoContext.Provider
      value={{
        stakingInfo,
        setStakingInfo,
        isAllVaultInfoFetched,
        refetchStakingContractInfo,
        refetchAllStakingContractInfo,
        refetchVaultUserInfo,
        refetchAllVaultUserInfo,
        refetchNodeCooldownInfo,
        refetchNodeStakedBalance,
        refetchNodeUnclaimedRewards,
        refetchContractGlobalVotingStake,
      }}
    >
      {children}
    </StakingInfoContext.Provider>
  );
};
