import { AppConfig } from 'global';

const parseJson = (value: string | undefined) => {
  try {
    return JSON.parse(value || '');
  } catch (err) {
    console.debug('Error parsing JSON value: ', err);
  }
};

window.appConfig = {
  appNetworks: parseJson(process.env.REACT_APP_NETWORK_CONFIGS),
  rpcUrls: parseJson(process.env.REACT_APP_CHAIN_RPCS),
  avgBlockTimeSeconds: parseJson(process.env.REACT_APP_AVG_BLOCKTIME_SEC),
  erc20Info: parseJson(process.env.REACT_APP_ERC20_INFO),
  vaults: parseJson(process.env.REACT_APP_VAULT_CONFIGS),
  launchPoolTokens: parseJson(process.env.REACT_APP_LAUNCHPOOL_TOKENS),
  erc4626Vault: parseJson(process.env.REACT_APP_ERC4626VAULT)?.address,
  v2Vaults: parseJson(process.env.REACT_APP_ERC4626VAULT)?.v2VaultsMapping,
  airdropManagers: parseJson(process.env.REACT_APP_AIRDROP_MANAGERS) || {},
} as AppConfig;

console.log('appConfig: ', window.appConfig);
